.password-generator {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin: 1rem auto;
}

.control-group {
  margin-bottom: 1.5rem;
}

.control-group label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
}

input[type="range"] {
  width: 100%;
  accent-color: #00aaff;
}

.toggles {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch.active {
  background-color: #00aaff;
}

.toggle-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch.active .toggle-handle {
  transform: translateX(26px);
}

.generate-btn {
  margin-top: 1.5rem;
  width: 100%;
  padding: 0.75rem;
  background-color: #00aaff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generate-btn:hover {
  background-color: #008ecc;
}

.output {
  margin-top: 2rem;
  text-align: center;
}

.output h3 {
  font-size: 1.25rem;
}

.output p {
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 8px;
  word-break: break-all;
  margin-bottom: 1rem;
}

.copy-btn {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.copy-btn:hover {
  background-color: #0056b3;
}
